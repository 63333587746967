import React from 'react';
import Section from 'components/atoms/Section';
import { createMdProcessor } from 'helpers/parser';

const processor = createMdProcessor({
  components: {
    table: (props: any) => <table className="u-table" {...props} />,
    h4: (props: any) => <h4 className="u-h4 text-primary" {...props} />,
  },
});

const md2react = (raw: any) => processor.processSync(raw).result;

interface PromoCostPricingSectionProps {
  title?: string;
  description?: string;
  body?: string;
}

const PromoCostPricingSection: React.FC<PromoCostPricingSectionProps> = ({
  title,
  description,
  body,
}) => (
  <Section className="bg-light-grey" title={title} body={description}>
    {md2react(body)}
  </Section>
);

export default PromoCostPricingSection;
