import React from 'react';
import { md2react } from 'helpers/parser';

interface PromoDetailProps {
  title?: string;
  body?: string;
  image?: string;
}

const PromoDetail: React.FC<PromoDetailProps> = ({ title, body, image }) => (
  <div className="u-container flex flex-col-reverse items-center md:flex-row text-dark-grey text-center md:text-left mt-10 md:px-20 lg:px-32 xl:px-40">
    <div className="px-6 mt-8 md:w-7/12 md:mt-0 md:mr-16">
      <div className="mb-2 text-xl font-bold">{title}</div>
      <div className={`text-md lg:text-lg hover-links`}>{md2react(body)}</div>
    </div>
    <div className="px-6 md:px-0 md:w-5/12">
      <img className="h-auto w-full" src={image} alt={title} />
    </div>
  </div>
);

export default PromoDetail;
