import React from 'react';
import Section from 'components/atoms/Section';
import PromoFaq from 'components/atoms/PromoFaq';

interface Faq {
  title?: string;
  body?: string;
}

interface PromoFaqSectionProps {
  title?: string;
  faqs?: Faq[];
  forceOpenAll?: boolean;
}

const PromoFaqSection: React.FC<PromoFaqSectionProps> = ({
  title,
  faqs,
  forceOpenAll = false,
}) => (
  <Section className="bg-light-grey" title={title}>
    <br />
    {faqs?.map(({ title, body }, index) => (
      <PromoFaq
        key={index}
        className="mt-5"
        initOpen={forceOpenAll}
        title={title}
        body={body}
      />
    ))}
  </Section>
);

export default PromoFaqSection;
