import React from 'react';
import Section from 'components/atoms/Section';
import PromoStep from 'components/atoms/PromoStep';

interface Step {
  title?: string;
  body?: string;
  image?: string;
}

interface PromoStepSectionProps {
  title?: string;
  body?: string;
  steps?: Step[];
}

const PromoStepSection: React.FC<PromoStepSectionProps> = ({
  title,
  body,
  steps,
}) => (
  <Section title={title} body={body}>
    {steps?.map(({ title, body, image }, index) => (
      <PromoStep key={index} title={title} body={body} image={image} />
    ))}
  </Section>
);

export default PromoStepSection;
