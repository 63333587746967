import React from 'react';
import cn from 'classnames';
import CtaButton from 'components/atoms/CtaButton';
import CalloutCardScene from './CalloutCardScene';
import { md2react } from 'helpers/parser';

interface HeroProps {
  brandLogo?: string;
  heading: string;
  content: string;
  button: {
    to: string;
    label: string;
  };
  footnote?: string;
  imageData?: {
    src: string;
    srcSet: string;
  };
  image?: string;
  cards?: any[];
  reverse?: boolean;
  mask?: boolean;
  style?: any;
  className?: any;
  calloutSceneWidths?: string;
}

const Hero: React.FC<HeroProps> = ({
  brandLogo,
  heading,
  content,
  button,
  footnote,
  image,
  imageData,
  className,
}) => {
  return (
    <div className={cn('text-dark-grey bg-gray-100 pt-20', className)}>
      <div className="u-container mx-auto flex flex-wrap items-center justify-start">
        <div className="w-full lg:w-1/2">
          <div className="mx-auto px-6 pt-16 lg:py-24 xl:py-28">
            {brandLogo && (
              <div className="max-w-hero-logo mb-8">
                <img src={brandLogo} className="object-contain" />
              </div>
            )}

            <h1 className="mb-8 u-h1">{heading}</h1>

            <div className="mb-8 prose-lg lg:prose-xl">{md2react(content)}</div>

            <CtaButton {...button} inverted />

            {footnote && (
              <div className="mt-8 prose-sm leading-snug">
                {md2react(footnote)}
              </div>
            )}
          </div>
        </div>

        <div className={cn('w-full lg:w-1/2 h-full relative pb-16')}>
          <img
            src={imageData && imageData.src ? imageData.src : image}
            srcSet={imageData && imageData.srcSet ? imageData.srcSet : ''}
            alt=""
            className="object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
