import { motion } from 'framer-motion';
import cn from 'classnames';
import React, { useState } from 'react';
import * as Plus from 'images/plus.inline.svg';
import { md2react, html2react } from 'helpers/parser';

interface PromoFaqProps {
  title?: string;
  body?: string;
  className?: string;
  initOpen?: boolean;
}

const PromoFaq: React.FC<PromoFaqProps> = ({
  title,
  body,
  className,
  initOpen = false,
}) => {
  const [open, setOpen] = useState(initOpen);

  return (
    <div
      className={cn(
        'bg-white px-5 py-5 pr-4 md:pr-6 text-left mx-auto',
        className,
      )}
    >
      <div>
        <button
          className="text-xl font-semibold w-full flex items-center justify-between focus:outline-none focus:ring-0 text-left"
          onClick={() => setOpen((isOpen) => !isOpen)}
        >
          <div className={cn('mr-2 md:mr-6 w-11/12', { 'text-primary': open })}>
            {html2react(title)}
          </div>
          <Plus
            className={cn('w-4 h-auto transition-transform duration-300', {
              'transform rotate-45 text-primary': open,
            })}
          />
        </button>
      </div>
      <motion.div
        className="overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: open ? 'auto' : 0 }}
        transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
      >
        <div className="pt-5 prose max-w-max">{md2react(body)}</div>
      </motion.div>
    </div>
  );
};

export default PromoFaq;
