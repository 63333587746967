import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Section from 'components/atoms/Section';
import PromotionHero from 'components/organisms/PromotionHero';
import PromoDetailSection from 'components/organisms/PromoDetailSection';
import PromoCostPricingSection from 'components/organisms/PromoCostPricingSection';
import PromoStepSection from 'components/organisms/PromoStepSection';
import PromoFaqSection from 'components/organisms/PromoFaqSection';

const PromotionContent = ({
  promotionId,
  hero,
  aquaSection,
  details,
  costPricing,
  greenSection,
  steps,
  faqs,
  forceOpenAll, // force open all faq accordion
}) => {
  return (
    <>
      <PromotionHero
        brandLogo={hero?.brandLogo}
        heading={hero?.title}
        content={hero?.body}
        footnote={hero?.footnote}
        image={hero?.image}
        button={{
          to: `https://go.skupos.com/engage/${
            !isNaN(promotionId) ? promotionId : ''
          }`,
          label: 'Enroll Now',
        }}
      />

      {(aquaSection?.title || aquaSection?.body) && (
        <Section
          className="bg-aqua underline-links"
          verticalPadding="pt-12 pb-10 lg:pt-20 lg:pb-16"
          inverted
          title={aquaSection?.title}
          titleFontSize="u-h4-semibold"
          body={aquaSection?.body}
          footnote={aquaSection?.footnote}
        />
      )}

      <PromoDetailSection
        title={details?.title}
        body={details?.body}
        footnote={details?.footnote}
        details={details?.details}
      />

      <PromoCostPricingSection
        title={costPricing?.title}
        description={costPricing?.description}
        body={costPricing?.body}
      />

      {(greenSection?.title || greenSection?.body) && (
        <Section
          className="bg-primary underline-links"
          verticalPadding="pt-12 pb-10 lg:pt-20 lg:pb-16"
          inverted
          title={greenSection?.title}
          titleFontSize="u-h4-semibold"
          body={greenSection?.body}
          footnote={greenSection?.footnote}
        />
      )}

      <PromoStepSection
        title={steps?.title}
        body={steps?.body}
        steps={steps?.steps}
      />

      <PromoFaqSection
        title={faqs?.title}
        faqs={faqs?.faqs}
        forceOpenAll={forceOpenAll}
      />
    </>
  );
};

export const PromotionPreview = ({ entry }) => (
  <PromotionContent {...entry.get('data').toJS()} forceOpenAll />
);

const Promotion = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout simple>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <PromotionContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PromotionByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        promotionId
        hero {
          title
          body
          footnote
          brandLogo
          image
        }
        aquaSection {
          title
          body
          footnote
        }
        details {
          title
          body
          footnote
          details {
            title
            body
            image
          }
        }
        costPricing {
          title
          description
          body
        }
        greenSection {
          title
          body
          footnote
        }
        steps {
          title
          body
          steps {
            title
            body
            image
          }
        }
        faqs {
          title
          faqs {
            title
            body
          }
        }
        seo {
          title
          description
          openGraphImage
        }
      }
      rawMarkdownBody
    }
  }
`;

export default Promotion;
