import React from 'react';
import Section from 'components/atoms/Section';
import PromoDetail from 'components/atoms/PromoDetail';

interface Detail {
  title?: string;
  body?: string;
  image?: string;
}

interface PromoDetailSectionProps {
  title?: string;
  body?: string;
  footnote?: string;
  details?: Detail[];
}

const PromoDetailSection: React.FC<PromoDetailSectionProps> = ({
  title,
  body,
  footnote,
  details,
}) => (
  <Section title={title} body={body} footnote={footnote}>
    {details?.map(({ title, body, image }, index) => (
      <PromoDetail key={index} title={title} body={body} image={image} />
    ))}
  </Section>
);

export default PromoDetailSection;
